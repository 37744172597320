.nav-bar{
    width: 100%;
    padding-top: 0.27rem;
}
.nav-bar::-webkit-scrollbar { 
    width: 0 !important;
    height: 0 !important;
    display: none;
 }

.nav-bar-container{
    margin-left:0.24rem;
    margin-right:0.68rem;
    overflow-x: scroll;
    overflow-y: hidden;
}
.nav-bar-container::-webkit-scrollbar { 
    width: 0 !important ;
    height: 0 !important;
    display: none;
}

.navItem{
    margin-right:0.25rem;
    white-space: nowrap;
}

.lh27{
    line-height: 0.37rem;
}
.lh26{
    line-height: 0.36rem;
}
.navLine{
    height: 3px;width:0.2rem;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius:0.17rem;position: absolute;
    bottom:-2px;
}
.lineBox{
    width:100%;
    position: absolute;
    left:0;
    bottom:-6px;
}
.navItem{
    position: relative;
}
.nav-bar-search {
    max-width: 0.3rem;
    /* margin-top: -0.1rem; */
    padding-top: 5px;
    right: 0.1rem;
    max-height: 0.3rem;
    position: absolute;
}
.nav-bar-search2 {
    width: .26rem;
    right: 0.4rem;
    position: absolute;
    margin-top: -0.18rem;
}