

.minePayVip-pay-web-container {
    position: absolute;
    height: 100%;
    width: 100%;
    border-width: 0px;
}
.MinePayVipList{
    margin-top: 0.8rem;
    height: 85vh;
    overflow: scroll;
}
.MinePayVipListBox{
    border-radius: 8px;
box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.10);
width: 3.35rem;
/* height: 159px; */
margin: 0.1rem auto;
padding: 15px;
box-sizing: border-box;

}
.MinePayVipListBoxGreen{
    color: #32D05F;
}
.MinePayVipListBox img{
    vertical-align: middle;
}
.MinePayVipListBoxBorder{
    width: 100%;
    height: 1px;
    background: #F0F0F0;
    margin: 0.1rem 0;
}
