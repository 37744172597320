.mineVipManage{
    padding-top: 0.9rem;
}
.mineVipManageBg{
    height: 0.58rem;
    margin-bottom: 20px;
}
.mineVipManageBgP{
    font-size: 15px;
    margin-bottom: 5px;
}
.mineVipManageImg{
    width: 14px;
    margin: 0 5px;
}
.mineVipManageColor{
    color: #FD65EA;
}
.mineVipManagePrivateModeDiv{
    width: 3.18rem;
    margin: 0 auto;
}
.mineVipManagePrivateModeDiv>img{
   vertical-align: top;
   margin-top: 2px;
   margin-right: 5px;
} 
.mineVipManagePrivateModeDiv .mineVipManagePrivateModeDivImg2{
    margin-top: 20px;
}
.mineVipManagePrivateModeDiv>div{
    width: 2.93rem;
    display: inline-block;
}
.mineVipManageBtn{
    width: 3.16rem;
    line-height: 0.48rem;
background: var(--Linear, linear-gradient(91deg, #FC66FF 2.91%, #FF5C5C 95.46%));
font-size: 0.15rem;
text-align: center;
color: #fff;
border-radius: 0.34rem;
position: absolute;
bottom: 20px;
left: 50%;
margin-left: -1.58rem;

}
.mineVipManageBgPrivateMode{
    margin-bottom: 80px;
}
.mineVipManagePrivateModeShow{
    display: none;
}
.mineVipManage .rv-password-input__security li{
    background: #F3F4F7;
}
.mineVipManagePrivateModeShowDiv{
    width: 2.5rem;
    margin:  0 auto;
}
.mineVipManagePrivateModeShowP2{
    margin: 10px 0;
}
.mineVipManagePrivateModeShowRemark{
    font-size: 13px;
    color: #FF2121;
    margin-left: 20px;
}