.chat-remark{
    height:100%;
    position: absolute;
    right:0;
    top: 0;
}
.chat-send-msg{
    height:0.5rem;
    padding-bottom:0.1rem;
    border-top: 1px solid rgba(219,219,219,1);;
    left: 0;
    bottom: 0;
    margin-top: auto;
}
.chat-msg-cxt{
    height: calc(100vh - 0.7rem);
    padding-bottom:0.8rem;
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
    /* .chat-send-msg{ */
        .chatSendMsgLine2{
        padding-bottom:0.16rem;
        padding-bottom:constant(safe-area-inset-bottom);
        padding-bottom: calc(30px + env(safe-area-inset-bottom));
    }
}

@keyframes bounceup{
    25% {-webkit-transform: translateX(0.12rem);}
    50%, 100% {-webkit-transform: translateX(0);}
    75% {-webkit-transform: translateX(-0.12rem);}
}

.animate-up{
    -webkit-animation:bounceup 0.6s linear both;
    animation:bounceup 0.6s linear both;
}

.chat-send-msg>div>input{
    display: inline-block;
    height:0.36rem;
    border-radius:0.28rem;
    background-color: #fafafa;
    width:2.4rem;
    padding-left:0.14rem;
    padding-right: 0.14rem;
    outline: none;
    border: none;
    margin-left:0.12rem;
}
.chat-send-msg>div>input::placeholder{
    color: #999999;
}
.chat-send-msg>div>input::placeholder{
    color: #999999;
}
.chat-send-msg>div>input::-ms-input-placeholder{
   color: #999999;
}
.chat-send-msg>div>input::-moz-placeholder{
   color: #999999;
}
.send-msg-icon{
    width:28px;
    height:28px;
    display: inline-block;
    margin-right:0.12rem;
    margin-left: 8px;
}
.chat-send-msg_send{
    width: 0.67rem;
line-height: 0.37rem;
border-radius: 0.12rem;
background: var(--Linear, linear-gradient(91deg, #FC66FF 2.91%, #FF5C5C 95.46%));
text-align: center;
color: #fff;
margin-right: 0.15rem;
}
.chatSendMsgLine2{
    width: 80%;
margin: 0 auto;
}


.chat-msg-item{
    width:3.35rem;
    margin-bottom:0.16rem;
}
.chat-msg-item-split{
    text-align: center;
    padding-top:0.12rem;
}

.chat-msg-content-o,.chat-msg-content-s,.chat-msg-content-g
{
    padding:10px 10px;
}

.chat-msg-content-o{
    background:#5E8BFF;
    border-radius: 8px 0px 8px 8px;
}

.chat-msg-content-s{
    background: #FD66F5;
    border-radius: 0px 8px 8px 8px;
}

.chat-msg-content-g{
    background: #FD66F5;
    border-radius: 0px 8px 8px 8px;
}

.money-hint {
    padding-top: 0.5rem;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9ea6b9;
}

.msg-gift-icon {
    width: 28px;
    height: 28px;
}

.msg-icon-touch{
    padding:4px 4px;
}

.edit-note-title {
    display: flex;
    justify-content: center;

}

.edit-note-submit {
    margin-top: 15px;
    padding: 4px 8px;
    width: 60px;
    border-radius: 8px;
}
.edit-note-name-input {
    width: 100%;
    padding:8px 8px;
    border-radius: 8px;
    border:none;
    background-color: lightgray;
}
.chat-msg-content-o-video{
    margin-bottom: -0.04rem;
    margin-right: 0.04rem;
}
.chat-msg-content-o-video-from{
    margin-bottom: -0.03rem;
    margin-right: 0.04rem;
    width: 0.25rem;
}