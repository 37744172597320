.mine-page {
  width: 100%;
  height: 95vh;
  overflow: scroll;
  padding-bottom: 1.2rem;
}
.mine-header {
  /* background: linear-gradient(180deg, #FF93F9 0%, rgba(255, 147, 249, 0) 100%); */
  background: url(../../assets/images/mine-back.png);
  height: 1.86rem;
  width: 100%;
  background-size: cover;
}
.avatars {
  width: 0.71rem;
  height: 0.71rem;
  border-radius: 100%;
  overflow: hidden;
}
.vipheaderClass {
  position: absolute;
  width: 0.89rem;
  height: 0.89rem;
  left: -0.1rem;
  touch-action: none;
}
.user-avatar {
  width: 0.71rem;
  height: 0.71rem;
  border-radius: 100%;
  object-fit: cover;
}
.avatars > div {
  display: inline-block;
  bottom: 0;
  left: 0;
  height: 0.2rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  line-height: 0.2rem;
}
.mine-top {
  padding-top: 0.4rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  position: relative;
}
.mine-edit {
  /* width: 1rem;
    height: 0.46rem;
    background: #F3F3F3;
    border-radius: 0.94rem;
    font-size: 0.2rem;
    letter-spacing: -0.48px;
    color: #A3A3A3;
    zoom: 0.5; */
  width: 53px;
  height: 23px;
  background: #f3f3f3;
  border-radius: 0.94rem;
  font-size: 0.2rem;
  letter-spacing: -0.48px;
  color: #a3a3a3;
}
.mine-edit .iconfont {
  font-size: 10px;
  transform: rotate(180deg);
}
.mine-edit > span {
  margin-left: 8px;
  font-size: 13px;
  display: inline-block;
  white-space: nowrap;
}
.mine-edit > i {
  display: inline-block;
  margin-right: 9px;
}

.nickname {
  font-style: normal;
  font-weight: 500;
  font-size: 0.2rem;
  letter-spacing: -0.24px;
  padding-bottom: 0.05rem;
}
.mine-vipClass{
    font-size: 10px;
    color:#FFEBC3;
    background: url('../../assets/images/mine/mine-vipbg.png');
    height: 0.72rem;
    width: 3.23rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin:  0 auto;
    box-sizing: border-box;
    padding-left: 0.17rem;
    padding-top: 0.1rem;
    position: relative;
}
.mine-vipClass>p{
    margin-left: 0.05rem;
}
.mine-vipClassBtn{
    width: 0.75rem;
    line-height: 0.28rem;
    border-radius: 46px;
border: 1px solid #FFF6E9;
background: linear-gradient(98deg, #363D57 5.93%, #43485C 103.71%);
/* backdrop-filter: blur(2px); */
text-align: center;
position: absolute;
top: 0.15rem;
right:0.2rem
}
.user-id {
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  letter-spacing: -0.24px;
  color: #999999;
}

.id-icon {
  width: 0.12rem;
  height: 0.12rem;
  border: 1px solid #999999;
  border-radius: 100%;
  display: inline-block;
  margin-left: 4px;
  text-align: center;
  line-height: 0.1rem;
}
.id-icon > .iconfont {
  font-size: 0.1rem;
}

.mine-center {
  margin-top: 0.23rem;
}

.mine-menu {
  width: 3.35rem;
  height: 0.96rem;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(232, 237, 241, 0.9);
  border-radius: 0.12rem;
  margin: 0 auto;
  position: relative;
  margin-top: -0.1rem;
}

.menuItem {
  width: 0.44rem;
  height: 0.44rem;
  border-radius: 0.12rem;
}
.menu-bg1 {
  background: linear-gradient(180deg, #ffd9b7 0%, #ffb967 100%);
}
.menu-bg2 {
  background: linear-gradient(180deg, #ffbefd 0%, #ff7bfc 100%);
}
.menu-bg3 {
  background: linear-gradient(180deg, #b6e9ff 0%, #6cd3ff 100%);
}
.menu-bg4 {
  background: linear-gradient(180deg, #cea8ff 0%, #a864ff 100%);
}

.menuItem > .theme-font {
  margin-top: 4px;
}
.menuItem > img {
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 100%;
}
.menuItem > i {
  font-size: 0.2rem;
  color: #ffffff;
}

.menu-list {
  width: 3.35rem;
  padding-top: 0.18rem;
  padding-bottom: .1rem;
  margin: 20px 20px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(232, 237, 241, 0.9);
  border-radius: 0.12rem;
}
.menuListItem {
  padding-bottom: 0.32rem;
  margin-left: 11px;
}
.menuListItem:hover,
.menuListItem:active {
  opacity: 0.85;
}
.listItemIcon > i {
  display: inline-block;
  font-size: 0.18rem;
  margin-left: 0.11rem;
}

.listItemIcon > span {
  display: inline-block;
  margin-left: 4px;
}

.right-menu > i {
  transform: rotate(180deg);
  display: inline-block;
  margin-right: 0.21rem;
  color: #c2c2c2;
}
.right-menu-switch {
  width: 0.51rem;
  height: 0.31rem;
  border-radius: 0.31rem;
  margin-right: 0.21rem;
  position: relative;
}
.right-menu-slider {
  height: 0.27rem;
  width: 0.27rem;
  border-radius: 0.27rem;
  background-color: #ffffff;
  position: absolute;
  right: 0.01rem;
  top: 0.02rem;
}
.mine-icon {
  width: 17px;
  height: 17px;
}
.centerItem > a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden-notice-dialog {
  /* position: absolute; */
  width: 283px;
  height: 167px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 12px;
}

.hidden-title {
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  display: flex;
  padding-top: 0.1rem;
}
.hidden-desc {
  font-size: 13px;
  padding: 10px 30px;
}
.hidden-confirm-btn {
  width: 94px;
  height: 33px;
  left: 0px;
  top: 0px;

  /* Linear */
  background: linear-gradient(91.22deg, #fc66ff 2.91%, #ff5c5c 95.46%);
  border-radius: 45px;
  font-size: 13px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
