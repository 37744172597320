.share{
    position: absolute;
    /* width: 376px; */
    /* height: 100%; */
    left: -1px;
    top: 0px;
    bottom:0px;
    padding-top: 30px;
    overflow: scroll;
    background: linear-gradient(180deg, #322CCE 0%, #322CCE 100%);
}
.share-nav-container {
    height:63px;
    width:100%;
    z-index: 2;
    background-color: transparent;
}
.share-nav{
    /* margin-left: 0.26rem; */
    margin-right:0.26rem;
    padding-left: 0.26rem;
}
.share-nav i{
    font-size:0.22rem;
    color:white;
}
.share-title {
    margin-top: -30px;
    width:100%;
    object-fit: contain;
    
}

.share-invite-back {
    margin-left: 22px;
    margin-right: 22px;
}
.share-container {
    width:100%;
}
.share-invite {
    margin-left: 22px;
    margin-right: 22px;
    height: 100px;
    background-image:url("../../assets/images/share/share-2.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.share-rule {
    background-color:white;
    border-radius: 10px;
    padding: 20px;

}

.share-rule >p {
    margin-bottom: 8px;
}

.share-btn-container {
    width:100%;
    height: 100px;
    position: fixed;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.share-btn {
    width: 375px;
}

.share-image-base{
    width: 304px;
    height: 323px;
    position: relative;
    background-image:url("../../assets/images/share/share-bg.png");
    background-size: cover;
}

.qr-code {
    position:absolute;
    right:30px;
    bottom:30px;
    width: 42px;
    height: 42px;
}

.qr-code >img {
    width: 42px;
    height: 42px;
}

.share-btn-save {
    /* border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: pink;
    background-color: pink;
    margin:10px; */

    width: 224px;
line-height: 31px;
border-radius: 80px;
background: var(--Linear, linear-gradient(91deg, #FC66FF 2.91%, #FF5C5C 95.46%));
color: #FFF;
margin: 20px auto;

}
.share-btn-copy {
    /* border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    background-color: white;
    margin:10px; */
    width: 224px;
line-height: 31px;
flex-shrink: 0;
border-radius: 80px;
background: rgba(255, 255, 255, 0.8);
color: #FD67FF;
margin: 10px auto;
}

.share-dialog {
    background: transparent;
}

.shareImageBaseTitle{
    border-radius: 31px;
    background: rgba(0, 0, 0, 0.4);
    min-width: 123px;
    height: 39px;
    padding-top: 10px;
    padding-left: 5px;
}